.mapboxgl-map{
    height: 100vh;
    width: 100vw;
    background-repeat: space;
}
.mapContainer {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100vh !important;
}
.content-card{
    display: inline-flex;
    width: 100vw;
    overflow-x: scroll;
    background-color: transparent !important;
    position: absolute;
    bottom: 0px;
}
.icon-calificacion{
    font-size: 32px;
}
.active-calificacion{
    color:rgb(243, 243, 13);
}

.img-detail{
    position: relative;
    width: 100%;
    margin-right: 12px;
}
